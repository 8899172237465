import React, { useContext } from "react"
import axios from "axios"
import classNames from "classnames"
import PropTypes from "prop-types"
import Modal from "../Modal"
import Video from "../Video"
import Icon from "../Icon"
import OutboundLink from "../OutboundLink"
import EducatorContext from "../../context/EducatorContext"

import "./styles.scss"

const Action = ({
  label,
  type,
  format,
  size,
  path,
  url,
  gradeLevel,
  locked,
  guidSrc,
  src,
  logName,
  azure_src,
  posterSrc,
  doNotLogClick,
  moduleName,
  autoplay,
  assetId,
  ccLang,
}) => {
  const {
    submittedFields,
    elementarySchoolProcessed,
    markElementarySchoolAsProcessed,
    middleSchoolProcessed,
    markMiddleSchoolAsProcessed,
    highSchoolProcessed,
    markHighSchoolAsProcessed,
  } = useContext(EducatorContext)

  const onClickHandler = event => {
    const fieldsPopulated = !!Object.keys(submittedFields).length
    const bothFormsSubmitted =
      highSchoolProcessed && middleSchoolProcessed && elementarySchoolProcessed
    if (gradeLevel && fieldsPopulated && !bothFormsSubmitted) {
      if (gradeLevel === "high" && !highSchoolProcessed) {
        // Process high school form
        return axios
          .post(
            `/api/form-entry`,
            {
              ...submittedFields,
              data: {
                ...submittedFields.data,
                Form: "High School",
              },
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then(response => {
            markHighSchoolAsProcessed()
          })
      }
      if (gradeLevel === "middle" && !middleSchoolProcessed) {
        // Process middle school form
        return axios
          .post(
            `/api/form-entry`,
            {
              ...submittedFields,
              data: {
                ...submittedFields.data,
                Form: "Middle School",
              },
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then(response => {
            markMiddleSchoolAsProcessed()
          })
      }
      if (gradeLevel === "elementary" && !elementarySchoolProcessed) {
        // Or process Elementary
        return axios
          .post(
            `/api/form-entry`,
            {
              ...submittedFields,
              data: {
                ...submittedFields.data,
                Form: "Elementary School",
              },
            },
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then(response => {
            markElementarySchoolAsProcessed()
          })
      }
    }
  }

  return (
    <div
      className={classNames(
        "resource__action",
        locked && "resource__action--locked"
      )}
    >
      {locked ? (
        <div className="resource__action-placeholder">
          <Icon name="lock" inline />
          {label}
        </div>
      ) : (
        <>
          {type === "external" && (
            <OutboundLink to={url} doNotLogClick={doNotLogClick} withIcon>
              {label}
            </OutboundLink>
          )}
          {type === "video" && (
            <Modal
              trigger={
                <button>
                  <Icon name="play" inline />
                  {label}
                </button>
              }
              video
            >
              {src && <Video
                src={src}
                azure_src={azure_src}
                posterSrc={posterSrc}
                autoplay={autoplay}
              />
              }
              {guidSrc && <Video
                guidSrc={guidSrc}
                azure_src={azure_src}
                posterSrc={posterSrc}
                autoplay={autoplay}
              />}
            </Modal>
          )}
          {type === "download" && (
            <a
              href={path}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClickHandler}
            >
              <Icon name="download" inline />
              {label}
            </a>
          )}
          {type === "module" && (
            <a
              href={path}
              target="_blank"
              data-click-type="module"
              data-module-name={moduleName || label}
              rel="noopener noreferrer"
              onClick={onClickHandler}
            >
              <Icon name="module" inline />
              {label}
            </a>
          )}
        </>
      )}

      {format && <span className="resource__format">{format}</span>}
      {size && <span className="resource__size">{size}</span>}
    </div>
  )
}

Action.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  format: PropTypes.string,
  size: PropTypes.string,
  path: PropTypes.string,
  url: PropTypes.string,
  youtube_id: PropTypes.string,
  vimeo_id: PropTypes.string,
  locked: PropTypes.bool,
  logName: PropTypes.string,
  doNotLogClick: PropTypes.bool,
  moduleName: PropTypes.string,
}

export default Action
