import React, { Component } from "react"
import PropTypes from "prop-types"
import { Container } from "../Grid"
import Icon from "../Icon"
import classNames from "classnames"
import "./styles.scss"

class Accordion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  getAccordionBodyClasses = () => {
    const { open } = this.state
    return classNames("accordion__body", open && "accordion__body--open")
  }

  handleClick = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    const { title, body } = this.props
    const { open } = this.state

    return (
      <Container>
        <div className="accordion">
          <button className="accordion__title" onClick={this.handleClick}>
            {title}
            {open ? <Icon name="caratup" /> : <Icon name="caratdown" />}
          </button>
          <div className={this.getAccordionBodyClasses()}>{body}</div>
        </div>
      </Container>
    )
  }
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
}

export default Accordion

/* Example Usage */
/*
<Accordion
  title="My Title: Clicking this opens drawer"
  body={
    <p>Here is some text which remains hidden until onClick
      <OutboundLink to="https://www.mywebsitelink.com">
        www.mywebsitelink.org
      </OutboundLink>
    </p>
  }
/>
*/
