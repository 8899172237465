import React from "react"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import { ParentAccess } from "../components/Form"
import Action from '../components/Resource/Action'
import Resource from "../components/Resource"
import OutboundLink from "../components/OutboundLink"
import Accordion from "../components/Accordion"
import { ImageVideoModal } from "../components/Modal"
import ParentContext from "../context/ParentContext"
import CoppaContext from "../context/CoppaContext"

const ParentCommunityToolkitPage = () => (
  <Layout>
    <SEO title="Parents &amp; Community" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Container>
        <Row>
          <Column span={7}>
            <h1>Parents and Community</h1>
            <h3 className="hero__subhead">
              Stay informed about the youth e-cigarette crisis and prevention
              efforts
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  INTRO ***************/}
    <Section className="lightgray">
      <Container>
        <Row>
          <Column span={10}>
            <h2 className="serif">
              The Facts About Vaping: What Every Parent Should Know
            </h2>
            <p>
              Access the resources below to learn more about the vaping crisis
              and ensure communities stay informed.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>

    {/***************  PARENTS AND COMMUNITY ***************/}
    <Section className="parents-community">
      <Container>
        <Row>
          <Column span={6}>
            <h2>Parents and Community</h2>
            <p>
              We know that parents and guardians play a critical role in
              influencing a child’s decision-making. The Parent and Community
              Toolkit is created for use at Parent Nights, Back-to-School
              Nights, PTA/PTO meetings, or other community events to share the
              facts about the dangers of vaping. Inside you’ll find tools to
              help guide the conversation and ignite discussion on how to help
              your community <strong>Be Vape Free</strong>.
            </p>
            <p>
              Share the facts, statistics, and a message from the former Surgeon
              General, who declared e-cigarette use among youth an epidemic in
              the United States.
            </p>
          </Column>
          <Column span={6}>
            <ImageVideoModal
              imgPlaceholder="img-parents-community-video-surgeon.jpg"
              src="https://catch-public.s3.us-east-2.amazonaws.com/programs/CATCH-My-Breath/videos/surgeon-general.mp4"
            />
          </Column>
        </Row>
      </Container>
    </Section>

    <div className="toolkit-partnerships-container">
      {/***************  PARENT AND COMMUNITY TOOLKIT ***************/}
      <Section className="toolkit--parent toolkit yellow-pattern">
        <Resource
          image="overview-video_2x.jpg"
          title="Parent/Community Video"
          description={
            <p>
              This video provides basic information about the youth vaping
              epidemic, e-cigarettes and vaping prevention efforts that can be
              utilized by parents and communities. The video may be used alone,
              or paired with the Powerpoint presentation and Toolkit below.
            </p>
          }
          actions={[
            {
              label: "Watch Video",
              src: "https://catch-public.s3.us-east-2.amazonaws.com/programs/CATCH-My-Breath/videos/catch_my_breath_parent_presentation.mp4",
              type: "video",
            },
            {
              label: "En Espanol",
              src: "https://catch-public.s3.us-east-2.amazonaws.com/programs/CATCH-My-Breath/videos/catch_my_breath_parent_presentation_spanish.mp4",
              type: "video",
            },
          ]}
          horizontal
        />
        <div id="parent-video-wrapper">
          <Container>
            <h2>Parent and Community Toolkit</h2>
            <Row>
              <Column span={6}>
                <CoppaContext.Consumer>
                  {({ coppaRestricted }) => (
                    <ParentContext.Consumer>
                      {({ parentFormProcessed }) => (
                        <Resource
                          image="img-toolkit-video.jpg"
                          subjects={["POWERPOINT PRESENTATION & TOOLKIT PDF"]}
                          title="Vaping and Electronic Cigarettes (E-cigs)"
                          grade="Parents and Community"
                          duration="18 minutes"
                          description={
                            <>
                              <p>
                                Download the “Vaping and Electronic Cigarettes
                                (E-cigs)” PowerPoint slides to share directly
                                with parents/guardians, educators, or community
                                members as a self-serve option.
                              </p>
                              <Action
                                label="Parent Slides"
                                path="https://docs.google.com/presentation/d/1BBMlvDGBHbtRp6zDIKBhA8nHWNlA-8lARpOFTKkkSMw/export/pptx"
                                type="download"
                                locked={coppaRestricted || !parentFormProcessed}
                              />
                              <Action
                                label="En Espanol"
                                path="https://docs.google.com/presentation/d/1P8xCLu3cgcNQV2IX9MkukBUCbnMH80GERgsGCUvtMBk/export/pptx"
                                type="download"
                                locked={coppaRestricted || !parentFormProcessed}
                              />
                              <p>
                                This toolkit is a way for parents to learn more
                                about the vaping epidemic, gauge their child's
                                risk of trying e-cigarettes, and find the best
                                strategy for them to talk to their child about
                                e-cigarettes.
                              </p>
                              <Action
                                label="Parent Toolkit"
                                path="/resources/CVS-Catch-Parent_Toolkit.pdf"
                                type="download"
                                locked={coppaRestricted || !parentFormProcessed}
                              />
                              <Action
                                label="En Espanol"
                                path="/resources/CVS-Catch-Parent_Toolkit-Spanish-V3.1.pdf"
                                type="download"
                                locked={coppaRestricted || !parentFormProcessed}
                              />
                            </>
                          }
                          actions={[]}
                        />
                      )}
                    </ParentContext.Consumer>
                  )}
                </CoppaContext.Consumer>
              </Column>
              <Column span={6}>
                <h2>
                  Sign up for immediate access to CATCH My Breath parent and
                  presenter guides
                </h2>
                <CoppaContext.Consumer>
                  {({ coppaRestricted }) =>
                    coppaRestricted ? (
                      <p>
                        Only users that are 13 years of age or older can
                        subscribe to updates!
                      </p>
                    ) : (
                      <ParentContext.Consumer>
                        {({
                          parentFormProcessed,
                          markParentFormAsProcessed,
                        }) => {
                          return !parentFormProcessed ? (
                            <ParentAccess
                              id="Parent Access Form"
                              onSubmit={() => markParentFormAsProcessed()}
                            />
                          ) : (
                            <p>
                              Thank you for submitting your information. The
                              CATCH My Breath content is now available for
                              download.
                            </p>
                          )
                        }}
                      </ParentContext.Consumer>
                    )
                  }
                </CoppaContext.Consumer>
              </Column>
            </Row>
          </Container>
        </div>
      </Section>

      {/***************  ADDITIONAL RESOURCES ***************/}
      <Section className="additional-resources yellow-pattern">
        <Container>
          <h2 id="additionalResources">Additional Resources</h2>
          <p>
            Find the latest resources from the foremost leaders in public health
            regarding e-cigarettes.
          </p>
          <Row>
            <Column span={8}>
              <Accordion
                title="CDC Resources"
                body={
                  <div>
                    <OutboundLink
                      to="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/Quick-Facts-on-the-Risks-of-E-cigarettes-for-Kids-Teens-and-Young-Adults.html"
                      withIcon
                    >
                      Quick Facts on the Risks of E-cigarettes for Kids, Teens,
                      and Young Adults
                    </OutboundLink>
                    <OutboundLink
                      to="https://www.cdc.gov/tobacco/basic_information/e-cigarettes/severe-lung-disease.html"
                      withIcon
                    >
                      Outbreak of Lung Injury Associated with the Use of
                      E-Cigarette, or Vaping, Products
                    </OutboundLink>
                  </div>
                }
              />

              <Accordion
                title="Food and Drug Administration"
                body={
                  <div>
                    <OutboundLink
                      to="https://www.fda.gov/news-events/public-health-focus/lung-illnesses-associated-use-vaping-products"
                      withIcon
                    >
                      Lung Illnesses Associated with Use of Vaping Products
                    </OutboundLink>
                    <OutboundLink
                      to="https://www.fda.gov/tobacco-products/fda-launches-new-campaign-real-cost-youth-e-cigarette-prevention-campaign"
                      withIcon
                    >
                      FDA Launches New Campaign: 'The Real Cost' Youth
                      E-Cigarette Prevention Campaign
                    </OutboundLink>
                    <OutboundLink
                      to="https://www.fda.gov/tobacco-products/public-health-education/real-cost-campaign"
                      withIcon
                    >
                      The Real Cost Campaign
                    </OutboundLink>
                  </div>
                }
              />

              <Accordion
                title="Surgeon General"
                body={
                  <div>
                    <OutboundLink
                      to="https://e-cigarettes.surgeongeneral.gov"
                      withIcon
                    >
                      About the Facts
                    </OutboundLink>
                    <OutboundLink
                      to="https://e-cigarettes.surgeongeneral.gov/documents/surgeon-generals-advisory-on-e-cigarette-use-among-youth-2018.pdf"
                      withIcon
                    >
                      Surgeon General's Advisory on E-cigarette Use Among Youth
                    </OutboundLink>
                    <OutboundLink
                      to="https://e-cigarettes.surgeongeneral.gov/documents/2016_sgr_full_report_non-508.pdf"
                      withIcon
                    >
                      E-Cigarette Use Among Youth and Young Adults – A Report of
                      the Surgeon General
                    </OutboundLink>
                  </div>
                }
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </div>
  </Layout>
)
export default ParentCommunityToolkitPage
