import { Container, Row, Column } from "../Grid"
import Action from "./Action"
import Image from "../Image"
import Icon from "../Icon"
import Video from "../Video"
import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"

const Resource = ({
  image,
  label,
  subjects,
  title,
  grade,
  duration,
  description,
  actions,
  horizontal,
  bgpattern,
  bgpatterntwo,
}) => {
  const renderImage = () => {
    const videos = actions.filter(action => {
      return action.type === "video";
    })
    const video = videos.length >= 1 ? videos[0] : false
    return (
      <div className="resource__image">
        {label && <div className="resource__label">{label}</div>}
        {video ? (
          <Video {...video} imgPlaceholder={image} />
        ) : (
          <Image filename={image} />
        )}
      </div>
    )
  }

  const renderContent = () => (
    <div className="resource__content">
      {subjects && (
        <div className="resource__subjects">{subjects.join(", ")}</div>
      )}
      <h3 className="resource__title">{title}</h3>
      {grade && <div className="resource__grade">{grade}</div>}
      {duration && (
        <div className="resource__duration">
          <Icon name="clock" inline />
          {duration}
        </div>
      )}
      {description && (
        <div className="resource__description">{description}</div>
      )}
      {actions && (
        <div className="resource__actions">
          {actions.map((action, i) => (
            <Action key={i} {...action} />
          ))}
        </div>
      )}
    </div>
  )

  if (horizontal) {
    return (
      <Container>
        <div className="resource resource--horizontal">
          <Row>
            <Column span={5}>{renderImage()}</Column>
            <Column span={7}>{renderContent()}</Column>
          </Row>
        </div>
      </Container>
    )
  }

  if (bgpattern) {
    return (
      <Container>
        <div className="resource resource--horizontal bg-pattern">
          <Row>
            <Column span={5}>{renderImage()}</Column>
            <Column span={7}>{renderContent()}</Column>
          </Row>
        </div>
      </Container>
    )
  }

  if (bgpatterntwo) {
    return (
      <Container>
        <div className="resource resource--horizontal bg-pattern-two">
          <Row>
            <Column span={5}>{renderImage()}</Column>
            <Column span={7}>{renderContent()}</Column>
          </Row>
        </div>
      </Container>
    )
  }

  return (
    <div className="resource resource--vertical">
      {renderImage()}
      {renderContent()}
    </div>
  )
}

Resource.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string,
  subjects: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  grade: PropTypes.string,
  duration: PropTypes.string,
  description: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  horizontal: PropTypes.bool,
  bgpattern: PropTypes.bool,
  bgpatterntwo: PropTypes.bool,
}

export default Resource

/* Example Usage */
/* 
<Resource
  label="Core Program Bundle"
  image="img-core-program-bundle.jpg"
  subjects={["Health"]}
  title="Introduction to the Risks of E-Cigarettes and Vaping"
  grade="5th Grade"
  duration="Four sessions, 30-40 minutes each"
  description={
    <>
      <p>
        Text goes here...
      </p>
    </>
  }
  actions={[
    {
      label: "Educator Guide", path: "../docs/cvs-catch-placeholder.zip", type: "download", locked: !formComplete,
    },
    {
      label: "Class Slides", path: "../docs/cvs-catch-placeholder.zip", type: "download", locked: !formComplete,
    },
  ]}
/> 
*/
